export const MOVE_COURSE = "MOVE_COURSE";
export const MOVE_GROUP = "MOVE_GROUP";

export const ADD_SEMESTER = "ADD_SEMESTER";
export const REMOVE_SEMESTER = "REMOVE_SEMESTER";
export const RESET_CURRICULUM = "RESET_CURRICULUM";

export const SET_START_SEMESTER = "SET_START_SEMESTER";

export const SET_CUSTOM_STUDIES = "SET_CUSTOM_STUDIES";

export const CHECK_COURSE_CONSTRAINTS = "CHECK_COURSE_CONSTRAINTS";

export const SET_COURSE_GRADE = "SET_COURSE_GRADE";

export const SET_EXAMPLE_CURRICULUM = "SET_EXAMPLE_CURRICULUM";

export const SET_APPLICATION_STATE = "SET_APPLICATION_STATE";
export const SET_UPLOADED_CURRICULUM = "SET_UPLOADED_CURRICULUM";
export const LOAD_SAVED_CURRICULUM = "LOAD_SAVED_CURRICULUM";

export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
